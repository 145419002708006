<template>
  <div class="boasVindas">
    <Loading v-if="!configBoasVindasTextos"/>
    <div v-if="configBoasVindasTextos">
      <HeaderVue :logo="configBoasVindas?.logo_hotel"/>
      <div class="containerDescricao">
        <div class="bolas">
          <div :class="this.idioma === 'pt-BR' ? 'bola bola-brasil selected width':  'bola bola-brasil'" @click="() => selecionarIdioma('pt-BR')"><img src="https://cdn.countryflags.com/thumbs/brazil/flag-round-250.png"></div>
          <div :class="this.idioma === 'en' ? 'bola bola-eua selected width':  'bola bola-brasil'" @click="() => selecionarIdioma('en')"><img src="https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png"></div>
          <div :class="this.idioma === 'es' ? 'bola bola-espanha selected width':  'bola bola-brasil'" @click="() => selecionarIdioma('es')"><img src="https://cdn.countryflags.com/thumbs/spain/flag-round-250.png"></div>
        </div>

        <div class="loading" v-if="!configBoasVindas?.imagem_hotel" style="margin-top: 20px; border-radius: 10px;width: 100%; max-width: 500px; height: 300px;">
          <div class="c-loader"></div>
        </div>

        <img v-if="configBoasVindas?.imagem_hotel" style="margin-top: 20px; border-radius: 10px;width: 100%; max-width: 500px;" :src="this.page === 1 ? configBoasVindas?.imagem_hotel : configBoasVindas?.imagem_hotel_2" alt="imagem hotel" id="imgBoasVindas1" />
        <div style="word-wrap: break-word; max-width: 500px;">
          <h2>{{this.page === 1 ? configBoasVindasTextos?.titulo_1 : configBoasVindasTextos?.titulo_2}}</h2>
          <p style="white-space: pre-line;">{{this.page === 1 ? configBoasVindasTextos?.descricao_1 : configBoasVindasTextos?.descricao_2}}</p>
        </div>
        <div class="containerButtonProximo">
          <div v-if="!tela_2" style="background-color: #156CF7; border-radius: 50%; width: 8px;height: 8px;"></div>
          <img v-if="tela_2" :src="this.page === 1 ? '/BoasVindas/ARRASTAR.svg' : '/BoasVindas/ARRASTAR2.svg'" id="imgBoasVindas1" />
          <button type="button" class="buttonProximo" @click="() => this.voltar()" v-if="this.page === 2 && tela_2">{{$t('webcheckin.Boas_vindas.voltar')}}</button>
          <button id="btn-iniciar-checkin" type="button" class="buttonProximo" @click="() => this.proximaPagina()">{{this.page === 1 && tela_2? $t('webcheckin.Boas_vindas.proximo') : $t('webcheckin.Boas_vindas.iniciarCheckin')}}</button>
        </div>
      </div> 
    </div>
    <FooterVue v-if="configBoasVindas"/>
  </div>
</template>

<script>
  import bowser from 'bowser';
  import boasVindasServices from '@/services/boasVindas.services';
  import HeaderVue from '@/components/Header.vue';
  import FooterVue from '@/components/Footer.vue';
  import Loading from '@/components/Loading.vue';
  import authServices from '../services/auth.services';
  import logs_web_checkinServices from '@/services/logs_web_checkin.services';

  export default {
    components: {
      HeaderVue,
      FooterVue,
      Loading
    },  
    data() {
      return {
        configBoasVindas: null,
        page: 1,
        tela_2: null,
        idioma: '',
        configBoasVindasTextos: null,
        idtValue: null
      }
    },
    
    methods: {

      async selecionarIdioma(idioma) {
        if (idioma === 'pt-BR') {
          this.idioma = idioma
          this.$i18n.locale = 'pt-BR'
          this.configBoasVindas = null;
          await this.requisitarBoasVindas(idioma)
        }

        if (idioma === 'en') {
          this.idioma = idioma
          this.$i18n.locale = 'en'
          this.configBoasVindas = null;
          await this.requisitarBoasVindas(idioma)
        }

        if (idioma === 'es') {
          this.idioma = idioma
          this.$i18n.locale = 'es'
          this.configBoasVindas = null;
          await this.requisitarBoasVindas(idioma)
        }
      },

      voltar() {
        this.page = 1;
      },

      proximaPagina() {
        if(this.page === 2 || !this.tela_2) {
          this.$router.push(`/webcheckin/`)
          return
        }
        this.page += 1 
      },

      async requisitarBoasVindas(idioma) {
        // console.log("BOAS VINDAS", idioma);
        const { idhotel, idreserva } = this.$route.params;
        await boasVindasServices.buscar( { idioma: idioma } )
      .then((resp) => {
        // console.log("XABLAU", idioma);
        localStorage.setItem('idhotel', idhotel);
        localStorage.setItem('idreserva', idreserva);
        localStorage.setItem('idioma', idioma);
        this.tela_2 = resp.data.tela_2
        this.configBoasVindas = resp.data;
        console.log(resp);
        this.$store.commit('storeImagemHotel', resp.data.logo_hotel);
      })
      .catch((error) => {
        console.log(error)
      })
      },

     async verificarDadosNavegadorCliente() {
        const browser = bowser.getParser(window.navigator.userAgent);

        const browserName = browser.getBrowserName();
        const browserVersion = browser.getBrowserVersion();
        const browserPlatformType = browser.getPlatformType();
        const browserOsName = browser.getOSName();
        const browserOsVersion = browser.getOSVersion();

        const { idhotel, idreserva } = this.$route.params;
        try {       
          await logs_web_checkinServices.inserirDadosNavegador({
            navegador: browserName,
            versao: browserVersion,
            link_web_checkin: `https://webcheckin.fd.economysoftware.com.br/${idhotel}/${idreserva}`,
            plataforma: browserPlatformType,
            sistemaNome: browserOsName,
            sistemaVersao: browserOsVersion,
            idreserva: idreserva
          })
        } catch (error) {
          console.log('erro ao salvar logs');
        }
      },

      async extractIdtFromUrl() {
        try {
          const currentUrl = window.location.href;
          if (currentUrl.includes('idt')) {
        const urlParts = currentUrl.split('?');

        const queryParams = urlParts[1];

        const paramPairs = queryParams.split('&');

        paramPairs.forEach((pair) => {
          const [key, value] = pair.split('=');
          if (key === 'idt') {
            this.idtValue = value;
          }
        });

        if (this.idtValue) {
          const { idreserva } = this.$route.params;
          await boasVindasServices.salvarIdTitulo({
            reserva: idreserva,
            id_titulo: this.idtValue
          })
        }

      }
        } catch (error) {
          console.log(error)
        }
      }
    },

    async created() {
      localStorage.removeItem('tokenAuthorization');
    },

    async updated() {
      await boasVindasServices.buscarTextos({ idioma: this.idioma })
        .then((resp) => {
          // console.log(resp);
          this.configBoasVindasTextos = resp.data;})
    },

    async mounted() {
      this.idioma = this.$i18n.locale
      const { idhotel, idreserva } = this.$route.params;
      localStorage.removeItem('tokenAuthorization');
      if(!localStorage.getItem('tokenAuthorization')) {
        await authServices.autenticar(idhotel)
         .then((resp) => {
           localStorage.setItem('tokenAuthorization', resp.data.token)
           localStorage.setItem('idhotel', idhotel);
           localStorage.setItem('idreserva', idreserva);
         })
         .catch((error) => {
           console.log(error);
         })
      }

      // let localStorageIdioma = localStorage.getItem('idioma')
      // console.log("AQUI: ", localStorageIdioma);
      await boasVindasServices.buscarTextos({ idioma: this.idioma } )
      .then((resp) => {
        // console.log(resp);
        this.configBoasVindasTextos = resp.data;
        if (resp.data?.config_aceite_termo) {
          if (resp.data?.config_aceite_termo === 'true' ) {
            localStorage.setItem('configAceiteTermo', true);
          }
          if (resp.data?.config_aceite_termo === 'false' ) {
            localStorage.setItem('configAceiteTermo', false);
          }
          if (resp.data?.config_aceite_termo === '' ) {
            localStorage.setItem('configAceiteTermo', true);
          }
        } else {
          localStorage.setItem('configAceiteTermo', true);
        }
      })

      await boasVindasServices.buscar( { idioma: 'pt-BR' } )
      .then((resp) => {
        localStorage.setItem('idhotel', idhotel);
        localStorage.setItem('idreserva', idreserva);
        this.tela_2 = resp.data.tela_2
        this.configBoasVindas = resp.data;
        this.$store.commit('storeImagemHotel', resp.data.logo_hotel);
      })
      .catch((error) => {
        console.log(error)
      })
      this.extractIdtFromUrl()
    }
  }

</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');

.bolas {
  display: flex;
  align-items: center;
}

.bola {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.bola-brasil img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bola-eua img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bola-espanha img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bola.selected {
  border: 2.5px solid rgb(90, 90, 90);
}

.bola.width {
  width: 40px;
  height: 40px;
}

.bola.selected.bola-brasil {
  border-color:#000000
}

.bola.selected.bola-eua {
  border-color: #000000
}

.bola.selected.bola-espanha {
  border-color: #000000
}


.boasVindas {
  margin-bottom: 40px;
  white-space: pre-line;
}

.containerDescricao {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

p {
  margin: 0;
  padding: 0;
  color: #5D5D5D;
  font-family: 'Poppins', sans-serif;
  font-size: small;
}

h2 {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  color: #3F4849;
  font-family: 'Poppins', sans-serif;
  font-size: medium;
}

.containerButtonProximo {
  background-color: #EFF0F2;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.buttonProximo {
  background-color: #156CF7;
  border: none;
  border-radius: 20px;
  color:white;
  padding-right: 15px;
  padding-left: 15px;
  height: 30px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-loader {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #156CF7;
  height: 50px;
  width: 50px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}
</style>